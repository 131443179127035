<template>
  <section class="promo--list">
    <banner-card v-for="banner in bannerList" :banner="banner" :key="banner.uuid" />
  </section>
</template>

<script>
import { mapState } from 'vuex';
import BannerCard from '@/components/banner/banner-card';

export default {
  components: { BannerCard },
  computed: {
    ...mapState({
      bannerList: (state) => state.banner.banners,
      copiedPromoCode: (state) => state.banner.copiedPromoCode,
    }),
  },
  methods: {
    copyCode(promoCode) {
      this.$store.commit('promo/SET_COPIED_PROMO_CODE', promoCode);
    },
  },
};
</script>
