<template>
  <div class="promo--card">
    <router-link :to="`/banner/detail/${banner.uuid}`">
      <img :src="banner.image" class="image" />
      <div class="title--text">
        {{ banner.title }}
      </div>
      <!--      <div class="top&#45;&#45;part" v-show="banner.end_date !== null">Periode: {{ period }}</div>-->
    </router-link>
    <!--    <div class="d-flex align-items-center" v-if="!isModal">-->
    <!--      <div class="left&#45;&#45;col">-->
    <!--        <router-link :to="`/banner/detail/${banner.uuid}`">-->
    <!--          <div class="bottom&#45;&#45;part">{{ $t('general.promo.code') }}: {{ banner.promo_code }}</div>-->
    <!--        </router-link>-->
    <!--      </div>-->
    <!--      <div class="right&#45;&#45;col">-->
    <!--        <button v-if="!copied" class="btn btn-primary" @click="copyCode(banner.promo_code)">-->
    <!--          {{ $t('general.promo.copy') }}-->
    <!--        </button>-->
    <!--        <button v-else class="btn btn&#45;&#45;green" @click="copyCode(banner.promo_code)">-->
    <!--          <i class="ion-checkmark mr-2"></i>-->
    <!--          <span>{{ $t('general.promo.copied') }}</span>-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="choose&#45;&#45;promo" v-else>-->
    <!--      <button class="btn btn-primary" @click="$emit('choosePromo', banner.promo_code)">-->
    <!--        {{ $t('general.promo.use') }}-->
    <!--      </button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'banner-card',
  props: ['banner', 'isModal'],
  computed: {
    ...mapState({
      copiedPromoCode: (state) => state.banner.copiedPromoCode,
    }),
    copied() {
      if (!this.copiedPromoCode) return false;
      return this.copiedPromoCode === this.banner.promo_code;
    },
    period() {
      if (this.banner.end_date === null) return null;
      return `${this.$date.format(this.banner.start_date, 'dd MMM yyyy')} - ${this.$date.format(
        this.banner.end_date,
        'dd MMM yyyy',
      )}`;
    },
  },
  methods: {
    async copyCode() {
      this.$copyText(this.banner.promo_code);
      this.$store.commit('banner/SET_COPIED_PROMO_CODE', this.banner.promo_code);
    },
  },
};
</script>

<style scoped></style>
