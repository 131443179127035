<template>
  <default-layout>
    <div class="container promo--list-container">
      <!-- JANGAN UNCOMMENT NANTI SERVER CRASH! -->
      <!--      <banner :banner-text="bannerText" />-->
      <banners class="mt-4" />
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
// import Banner from '@/components/utils/page-banner';
import Banners from '@/components/banner/banners';

export default {
  name: 'banner',
  components: {
    DefaultLayout,
    // Banner,
    Banners,
  },
  data: () => ({
    bannerText: 'Promo',
  }),
  async fetch({ store, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    await store.dispatch('banner/restoreInitialState');
    await store.dispatch('banner/getBanner');
  },
};
</script>
